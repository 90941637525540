import * as React from "react";

import { withStores } from "stores";
import { observer } from "mobx-react";
import { AuthenticationState } from "bernie-context";

import { ProductCollection } from "@shared-ui/retail-insurtech-product-collection";
import { InsurtechProductCollectionProps as Props } from "./typings";

export const InsurtechProductCollection = withStores("context")(
  observer((props: Props) => {
    const { context } = props;

    const isAuthenticated = context.user.authState === AuthenticationState.AUTHENTICATED;
    return isAuthenticated ? <ProductCollection /> : null;
  })
);

export default InsurtechProductCollection;
